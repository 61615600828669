
import { computed, defineComponent } from "vue";
import { formatDate, subTotal, totalInt } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";
import { getDiscountValues } from "@/graphql/ticket/ticket";

export default defineComponent({
  name: "TicketRapportDetailTables",
  props: ["ticket", "loading"],
  setup(props) {
    return {
      subTotal,
      totalInt,
      formatDate,
      currencySymbol: activeActivity.value.currencySymbol,
      sign: props.ticket?.parentId ? -1 : 1,
      resume: computed(() => getDiscountValues(props.ticket)),
    };
  },
});
